// // Here you can add other styles

// /**
// * Template Name: Power Kids
// * Updated: Now 30 2023
// * Author: Power Kids Group
// */
// /* power kids web app codes */
// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');
// main button[type=submit], main button[type=button] {
//   border: 0px;
//   font-weight: 600;
//   box-sizing: border-box;
//   -moz-box-sizing: border-box;
//   -webkit-box-sizing: border-box;
//   font-size: 14px;
//   margin: 0px 0px 10px 0px;
// }
// .btn-n {
//   font-size: 14px !important;
//   padding: 15px 20px !important;
// }
// .btn-s {
//   font-size: 13px !important;
//   padding: 8px 12px !important;
// }
// main button[type=submit]:hover, main button[type=submit]:focus, main button[type=button]:hover, main button[type=button]:focus {
//   border: 0px;
// }
// form-floating > label {
//   position: absolute;
//   top: 0;
//   left: 0;
//   z-index: 2;
//   height: 100%;
//   padding: 1rem 0.75rem;
//   overflow: hidden;
//   text-align: start;
//   text-overflow: ellipsis;
//   white-space: nowrap;
//   pointer-events: none;
//   border: var(--bs-border-width) solid transparent;
//   transform-origin: 0 0;
//   transition: opacity .1s ease-in-out, transform .1s ease-in-out;
// }
// .form-floating > .form-control, .form-floating > .form-control-plaintext, .form-floating > .form-select {
//   height: calc(3.5rem + calc(var(--bs-border-width) * 2));
//   min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
//   line-height: 1.25;
// }
.main input[type=text], main input[type=password], .form-select, .main textarea input{
  font-size: 16px;
  color: rgb(49, 49, 49);
  width: 100%;
  background-color: rgb(249, 250, 251);
  border-width: 0px;
  // border-style: initial;
  // border-color: initial;
  // border-image: initial;
  padding: 20px 8px 20px 8px;
  border-radius: 8px;
  transition: all 0.3s ease 0s;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.main input[type=text]:focus, .main input[type=password]:focus {
  outline: none;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(210, 219, 227);
  border-image: initial;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.main input[type=text]:focus, .main input[type=password]:placeholder {
  font-size: 14px;
  color: rgb(49, 49, 49);
  width: 100%;
  background-color: rgb(249, 250, 251);
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  padding: 10px 38px 10px 8px;
  border-radius: 8px;
  transition: all 0.3s ease 0s;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  outline: none;
}
// .dataTables_wrapper {
//   border: 6px solid #f7fbff;
//   border-radius: 8px;
//   padding: 8px;
// }
// .btn-primary {
//   background-color: #96D412 !important;
//   border: 0px;
//   padding: 6px 14px;
//   color: #000 !important;
//   font-weight: 600;
//   box-sizing: border-box;
//   -moz-box-sizing: border-box;
//   -webkit-box-sizing: border-box;
//   opacity: 1 !important;
//   font-size: 14px;
// }
// .btn-primary:hover, .btn-primary:active {
//   background-color: #82b80f !important;
// }
// .btn-secondary {
//   background-color: #000;
//   border: 0px;
//   color: #fff;
//   font-weight: 600;
//   box-sizing: border-box;
//   -moz-box-sizing: border-box;
//   -webkit-box-sizing: border-box;
//   font-size: 14px;
// }
// .form-floating {
//   padding-bottom: 15px;
// }
// .logo img {
//   width: 205px;
// }
// .footer .copyright {
//   text-align: center;
//   color: rgb(155, 168, 182) !important;
//   font-size: 12px;
// }
// table td {
//   font-size: 13px;
//   font-weight: 500;
//   // color: rgb(49, 49, 49) !important;
// }
// th {
//   cursor: pointer;
//   /* Add more styles if needed */
// }

// /* Additional styling for the sorting arrows, if necessary */
// .sort-indicator {
//   font-size: 0.8em;
// }
// .table-stripe > tbody > tr:nth-child(2n+1) > td, .table-stripe > tbody > tr:nth-child(2n+1) > th {
//   background-color: rgb(247, 250, 253) !important;
// }
// .datatable-table > tbody > tr > td, .datatable-table > tbody > tr > th, .datatable-table > tfoot > tr > td, .datatable-table > tfoot > tr > th, .datatable-table > thead > tr > td, .datatable-table > thead > tr > th {
//   vertical-align: top;
//   padding: 8px 10px;
// }
// .datatable-input {
//   font-size: 14px;
//   color: rgb(49, 49, 49);
//   width: 100%;
//   background-color: rgb(249, 250, 251);
//   border-width: 0px;
//   border-style: initial;
//   border-color: initial;
//   border-image: initial;
//   padding: 10px 38px 10px 8px;
//   border-radius: 8px;
//   transition: all 0.3s ease 0s;
//   outline: none;
// }
// .datatable-input:placeholder {
//   font-size: 14px;
//   color: rgb(1, 41, 112);
//   width: 100%;
//   background-color: rgb(249, 250, 251);
//   border-width: 0px;
//   border-style: initial;
//   border-color: initial;
//   border-image: initial;
//   padding: 10px 38px 10px 8px;
//   border-radius: 8px;
//   transition: all 0.3s ease 0s;
//   box-sizing: border-box;
//   -moz-box-sizing: border-box;
//   -webkit-box-sizing: border-box;
//   outline: none;
// }
// .datatable-selector {
//   font-size: 13px;
//   color: rgb(1, 41, 112);
//   width: auto;
//   background-color: rgb(249, 250, 251);
//   border-width: 0px;
//   border-style: initial;
//   border-color: initial;
//   border-image: initial;
//   padding: 10px 38px 10px 8px;
//   border-radius: 8px;
//   transition: all 0.3s ease 0s;
//   box-sizing: border-box;
//   -moz-box-sizing: border-box;
//   -webkit-box-sizing: border-box;
// }
// .datatable-selector:focus, .datatable-selector:hover, .datatable-selector::placeholder {
//   outline: none;
//   border-style: solid;
//   border-color: rgb(210, 219, 227);
//   border-image: initial;
//   box-sizing: border-box;
//   -moz-box-sizing: border-box;
//   -webkit-box-sizing: border-box;
// }
// .datatable-input input:focus, .datatable-input input:hover {
//   background-color: #A5E61A;
//   color: #000;
//   border: 0px;
//   outline: none;
// }
// .dataTables_wrapper input[type=text], .dataTables_wrapper input[type=search] {
//   font-size: 14px;
//   // color: rgb(49, 49, 49);
//   width: 100%;
//   background-color: rgb(249, 250, 251);
//   border-width: 0px;
//   border-style: initial;
//   border-color: initial;
//   border-image: initial;
//   padding: 10px 38px 10px 8px;
//   border-radius: 8px;
//   transition: all 0.3s ease 0s;
//   outline: none;
// }
// .dataTables_wrapper .dataTables_length select {
//   font-size: 13px;
//   color: rgb(1, 41, 112);
//   width: auto;
//   background-color: rgb(249, 250, 251);
//   border-width: 0px;
//   border-style: initial;
//   border-color: initial;
//   border-image: initial;
//   padding: 10px 38px 10px 8px;
//   border-radius: 8px;
//   transition: all 0.3s ease 0s;
//   box-sizing: border-box;
//   -moz-box-sizing: border-box;
//   -webkit-box-sizing: border-box;
// }
// .dataTables_wrapper .dataTables_length select:focus, .dataTables_wrapper .dataTables_length select:hover, .dataTables_wrapper .dataTables_length select::placeholder {
//   outline: none;
//   border-style: solid;
//   border-color: rgb(210, 219, 227);
//   border-image: initial;
//   box-sizing: border-box;
//   -moz-box-sizing: border-box;
//   -webkit-box-sizing: border-box;
// }
// .dataTables_wrapper .dataTables_length label {
//   font-size: 13px;
// }
// .dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
//   background-color: #F4F7FF;
//   background: #F4F7FF;
//   border: 1px solid transparent;
//   text-decoration: none;
//   color: #333;
//   cursor: pointer;
//   font-size: 13px;
//   border-radius: 4px;
// }
// .dataTables_wrapper .dataTables_paginate .paginate_button {
//   /*background-color: #F4F7FF;
// 	 background: #F4F7FF;*/
//   border: 1px solid transparent;
//   text-decoration: none;
//   color: #333;
//   cursor: pointer;
//   font-size: 13px;
//   border-radius: 4px;
// }
// .dataTables_wrapper .dataTables_info {
//   font-size: 13px;
// }
// .dataTables_wrapper tbody th, .dataTables_wrapper tbody td {
//   /* padding: 6px 4px !important; */
// }
// .dataTables_wrapper tbody tr.selected > * {
//   background-color: #FFE3DA !important;
//   box-shadow: inset 0 0 0 9999px rgba(255, 227, 218, 1) !important;
//   /* border-bottom: 1px solid #fff; */
//   /* box-sizing: border-box; */
//   -moz-box-sizing: border-box;
//   /* -webkit-box-sizing: border-box; */
// }
// .datatable-pagination .datatable-active a, .datatable-pagination .datatable-active a:focus, .datatable-pagination .datatable-active a:hover, .datatable-pagination .datatable-active button, .datatable-pagination .datatable-active button:focus, .datatable-pagination .datatable-active button:hover {
//   background-color: #F4F7FF;
// }
// table.dataTable > thead > tr > th, table.dataTable > thead > tr > td, table.dataTable.no-footer {
//   border-bottom: 1px solid #d6ddf3 !important;
// }
// .datatable th {
//   color: #1E1E1E;
//   font-size: 13px;
// }
// .text-green {
//   color: #7EAA1F;
// }
// .text-orange {
//   color: #ED9C00;
// }
// .text-red {
//   color: #FF7373;
// }
// .text-blue {
//   color: rgb(52, 195, 240);
// }
// .bg-orange {
//   background-color: rgb(232, 80, 29);
// }
// .bg-blue {
//   background-color: rgb(52, 195, 240);
// }
// .bg-green {
//   background-color: rgb(178, 220, 90);
// }
// .bg-red {
//   background-color: rgb(232, 80, 29);
// }
// .bg-soft-orange {
//   background-color: #FFF4DE;
// }
// .bg-soft-green {
//   background-color: #ECFFC3;
// }
// .bg-soft-red {
//   background-color: #FFEAEA;
// }
// .bg-soft-grey {
//   background-color: #E5EFFA;
// }
// .datatable-dropdown label {
//   font-size: 13px;
// }
// .datatable-info {
//   font-size: 13px;
// }
// .datatable-pagination {
//   font-size: 13px;
// }
// .header-nav .nav-profile {
//   color: rgb(49, 49, 49);
// }
// .rounded-images {
//   border-radius: 8px !important;
// }
// .progressbar-formatting {
//   display: block;
//   margin: 0px !important;
// }
// .progress {
//   width: 60px;
//   height: 4px;
//   margin: 0px !important;
// }
// .progress-wrapper {
//   width: 100px;
//   position: relative;
//   padding-top: 10px;
// }
// .progress-wrapper span {
//   display: block;
//   position: absolute;
//   right: 0px;
//   font-size: 12px;
//   color: rgb(49, 49, 49);
//   top: 3px;
//   padding: 0px 2px;
// }
// .progress-red {
//   background-color: rgb(255, 142, 142);
// }
// .progress-orange {
//   background-color: rgb(255, 208, 116);
// }
// .progress-blue {
//   background-color: rgb(93, 199, 236);
// }
// .progress-green {
//   background-color: rgb(178, 220, 90);
// }
/* login page codes */

.appContentBg {
  font-family: Inter, sans-serif;
  color: rgb(68, 68, 68);
  background: url("../assets/images/bg-bottom.png") left bottom no-repeat fixed, url("../assets/images/bg-top.png") right top no-repeat rgb(246, 249, 255);
  background-position: left bottom, right top;
  background-repeat: no-repeat, no-repeat;
}

.login {
  background-image: url("../assets/images/login-bg.jpg");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
}
.register {
  font-family: Inter, sans-serif;
  color: rgb(68, 68, 68);
  background: url("../assets/images/bg-bottom.png") left bottom no-repeat fixed, url("../assets/images/bg-top.png") right top no-repeat rgb(246, 249, 255);
  background-position: left bottom, right top;
  background-repeat: no-repeat, no-repeat;
}
.lastRegister {
  font-family: Inter, sans-serif;
  color: rgb(68, 68, 68);
  background: transparent;
  background-position: left bottom, right top;
  background-repeat: no-repeat, no-repeat;
}
.register-form {
  margin-bottom: 30px;
  box-shadow: rgba(1, 41, 112, 0.1) 0px 0px 30px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  border-radius: 12px;
  padding: 20px 10px;
  transition: all 0.3s;
  max-width: 1100px;
  margin: 30px auto;
  background-color: white;
}
// .login-logo {}
// .login-logo img {
//   width: 160px;
// }
// .remember-me-btn {
//   color: #899bbd;
//   font-size: 13px;
//   font-weight: 400;
// }
// .loginText {
//   color: #899bbd;
//   font-size: 13px;
//   font-weight: 400;
// }
// /* login page codes */
// .value-text-wrapper {
//   width: auto;
//   border-radius: 2px;
//   display: inline-block;
//   padding: 0px 4px;
// }
// .table-row-image {
//   width: 28px;
//   height: 28px;
//   border-radius: 4px;
//   display: inline-block;
//   overflow: hidden;
//   vertical-align: middle;
//   margin-right: 6px;
// }
// .table-row-image img {
//   width: auto;
//   height: 30px;
// }
// .main table td {
//   font-weight: 500;
// }
// .main table td a {
//   color: rgb(49, 49, 49) !important;
// }
.profile-card-image {
  width: 96px;
  height: 96px;
  border-radius: 8px;
  display: inline-block;
  overflow: hidden;
  margin-right: 6px;
  vertical-align: top;
}
.profile-card-image img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}
.profile-name-area {
  display: inline-block;
  vertical-align: top;
}
.profile-name-text {
  font-weight: bold;
  font-size: 18px;
  color: rgb(49, 49, 49);
}
.profile-name-point {
  font-size: 24px;
  font-weight: bold;
  color: #E8501D;
}
.profile-name-area p {
  margin: 0px;
  padding: 0px;
  margin-bottom: 10px;
}
.profile-name-point span {
  font-size: 13px !important;
  color: rgb(49, 49, 49) !important;
  font-weight: 500 !important;
}
.main .nav-pills .nav-link.active, .main .nav-pills .show > .main .nav-link {
  background-color: #E8501D;
  color: #ffffff !important;
}
.main .nav-link {
  color: rgb(137 155 189) !important;
  background-color: #f7f9ff;
  margin-right: 10px;
  font-size: 15px;
}
.main .nav-pills {
  padding: 20px 0px 0px 0px;
}
.profile-data-row-header {
  color: #80B50F;
  font-weight: 600;
  display: block;
  font-size: 14px;
}
.profile-data-row-data {
  font-size: 13px;
  color: rgb(49, 49, 49) !important;
  font-weight: 500;
  display: block;
}
.profile-data-row {
  padding: 10px 0px;
}
.profile-data-area {
  margin-top: 20px;
}
// .col-form-label {
//   font-size: 13px;
//   font-weight: 600;
//   display: block;
//   margin: 20px 0px 5px 0px;
// }
// .main .form-control:disabled {
//   background-color: #eef2f7;
//   color: #899bbd;
// }
// .main .form-floating > .form-control:disabled ~ label, .main .form-floating > :disabled ~ label {
//   color: #778692;
// }
// .main .form-floating > .form-control:disabled ~ label::after, .main .form-floating > :disabled ~ label::after {
//   background-color: #fff;
// }
// .alert {
//   font-size: 13px;
//   padding: 6px 8px;
//   border: 0px;
// }
// table.dataTable thead > tr > th.sorting:before, table.dataTable thead > tr > th.sorting:after, table.dataTable thead > tr > th.sorting_asc:before, table.dataTable thead > tr > th.sorting_asc:after, table.dataTable thead > tr > th.sorting_desc:before, table.dataTable thead > tr > th.sorting_desc:after, table.dataTable thead > tr > th.sorting_asc_disabled:before, table.dataTable thead > tr > th.sorting_asc_disabled:after, table.dataTable thead > tr > th.sorting_desc_disabled:before, table.dataTable thead > tr > th.sorting_desc_disabled:after, table.dataTable thead > tr > td.sorting:before, table.dataTable thead > tr > td.sorting:after, table.dataTable thead > tr > td.sorting_asc:before, table.dataTable thead > tr > td.sorting_asc:after, table.dataTable thead > tr > td.sorting_desc:before, table.dataTable thead > tr > td.sorting_desc:after, table.dataTable thead > tr > td.sorting_asc_disabled:before, table.dataTable thead > tr > td.sorting_asc_disabled:after, table.dataTable thead > tr > td.sorting_desc_disabled:before, table.dataTable thead > tr > td.sorting_desc_disabled:after {
//   font-size: .6em !important;
// }
// .modalIcon {
//   color: #000;
//   background: #C2FF32;
//   font-size: 48px;
//   line-height: 80px;
//   width: 80px;
//   height: 80px;
//   display: inline-block;
//   text-align: center;
//   border-radius: 100%;
// }
// .modal-title {
//   margin: 20px auto;
//   color: #000;
//   font-weight: 600;
// }
// .modal-body {
//   color: #595959;
//   font-weight: 400;
//   font-size: 15px;
// }
// .modal-header, .modal-footer {
//   border: 0px;
//   text-align: center;
//   display: block;
// }
// .form-floating .measurementType {
//   position: absolute;
//   right: 40px;
//   bottom: 18px;
//   font-size: 12px;
//   background-color: #f7f9ff;
//   padding: 2px 8px;
//   border-radius: 100px;
//   color: #5c6670;
//   font-weight: 500;
// }
// .smallText {
//   font-size: 13px;
//   color: #899bbd;
//   font-weight: 400;
// }
// .smallText .underline {
//   text-decoration: underline;
// }
// .alert-info {
//   background-color: #ffe7de;
//   color: #bd8470;
// }
// .alert-notify {
//   background-color: #f1f7ff;
//   color: #464a51;
// }
// .fileInput {
//   height: 80px !important;
//   padding-top: 40px !important;
//   padding-left: 25px !important;
// }
// .form-multi-select {
//   min-height: 110px !important;
//   padding-top: 35px !important;
// }
// /* power kids web app codes */
// /*--------------------------------------------------------------
// # General
// --------------------------------------------------------------*/ :root {
//   scroll-behavior: smooth;
// }
// body {
//   font-family: Inter, sans-serif;
//   color: rgb(68, 68, 68);
// //   background: url(../img/bg-bottom.png) left bottom no-repeat fixed, url(../img/bg-top.png) right top no-repeat rgb(246, 249, 255);
//   background-position: left bottom, right top;
//   background-repeat: no-repeat, no-repeat;
// }
// a {
//   color: rgb(232, 80, 29);
//   font-weight: 600;
//   text-decoration: none;
// }
// a:hover {
//   color: #717ff5;
//   text-decoration: none;
// }
// h1, h2, h3, h4, h5, h6 {
//   font-family: Inter, sans-serif;
// }
// /*--------------------------------------------------------------
// # Main
// --------------------------------------------------------------*/
// #main {
//   margin-top: 60px;
//   padding: 20px 30px;
//   transition: all 0.3s;
// }
// @media (max-width: 1199px) {
//   #main {
//     padding: 20px;
//   }
// }
// /*--------------------------------------------------------------
// # Page Title
// --------------------------------------------------------------*/
// .pagetitle {
//   margin-bottom: 10px;
// }
// .pagetitle h1 {
//   font-size: 24px;
//   margin-bottom: 10px;
//   font-weight: 600;
//   color: rgb(0, 0, 0);
//   margin-top: 10px;
// }
// /*--------------------------------------------------------------
// # Back to top button
// --------------------------------------------------------------*/
// .back-to-top {
//   position: fixed;
//   visibility: hidden;
//   opacity: 0;
//   right: 15px;
//   bottom: 15px;
//   z-index: 99999;
//   background: #4154f1;
//   width: 40px;
//   height: 40px;
//   border-radius: 4px;
//   transition: all 0.4s;
// }
// .back-to-top i {
//   font-size: 24px;
//   color: #fff;
//   line-height: 0;
// }
// .back-to-top:hover {
//   background: #6776f4;
//   color: #fff;
// }
// .back-to-top.active {
//   visibility: visible;
//   opacity: 1;
// }
// /*--------------------------------------------------------------
// # Override some default Bootstrap stylings
// --------------------------------------------------------------*/
// /* Dropdown menus */
// .dropdown-menu {
//   border-radius: 4px;
//   padding: 10px 0;
//   animation-name: dropdown-animate;
//   animation-duration: 0.2s;
//   animation-fill-mode: both;
//   border: 0;
//   box-shadow: 0 5px 30px 0 rgba(82, 63, 105, 0.2);
// }
.dropdown-menu .dropdown-header, .dropdown-menu .dropdown-footer {
  text-align: center;
  font-size: 15px;
  padding: 10px 25px;
  color: black;
}
// .dropdown-menu .dropdown-footer a {
//   color: #444444;
//   text-decoration: underline;
// }
// .dropdown-menu .dropdown-footer a:hover {
//   text-decoration: none;
// }
// .dropdown-menu .dropdown-divider {
//   color: #a5c5fe;
//   margin: 0;
// }
// .dropdown-menu .dropdown-item {
//   font-size: 14px;
//   padding: 10px 15px;
//   transition: 0.3s;
// }
// .dropdown-menu .dropdown-item i {
//   margin-right: 10px;
//   font-size: 18px;
//   line-height: 0;
// }
// .dropdown-menu .dropdown-item:hover {
//   background-color: #f6f9ff;
// }
// @media (min-width: 768px) {
//   .dropdown-menu-arrow::before {
//     content: "";
//     width: 13px;
//     height: 13px;
//     background: #fff;
//     position: absolute;
//     top: -7px;
//     right: 20px;
//     transform: rotate(45deg);
//     border-top: 1px solid #eaedf1;
//     border-left: 1px solid #eaedf1;
//   }
// }
// @keyframes dropdown-animate {
//   0% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
//   0% {
//     opacity: 0;
//   }
// }
// /* Light Backgrounds */
// .bg-primary-light {
//   background-color: #cfe2ff;
//   border-color: #cfe2ff;
// }
// .bg-secondary-light {
//   background-color: #e2e3e5;
//   border-color: #e2e3e5;
// }
// .bg-success-light {
//   background-color: #d1e7dd;
//   border-color: #d1e7dd;
// }
// .bg-danger-light {
//   background-color: #f8d7da;
//   border-color: #f8d7da;
// }
// .bg-warning-light {
//   background-color: #fff3cd;
//   border-color: #fff3cd;
// }
// .bg-info-light {
//   background-color: #cff4fc;
//   border-color: #cff4fc;
// }
// .bg-dark-light {
//   background-color: #d3d3d4;
//   border-color: #d3d3d4;
// }
// /* Card */
.card {
  margin-bottom: 30px;
  // box-shadow: rgba(1, 41, 112, 0.1) 0px 0px 30px;
  // border-width: initial;
  border-style: none;
  // border-color: initial;
  // border-image: initial;
  border-radius: 12px;
}
.card-header, .card-footer {
  border-color: #ebeef4;
  background-color: #fff;
  color: #798eb3;
  padding: 15px;
}
.card-title {
  font-size: 18px;
  font-weight: 500;
  color: rgb(49, 49, 49);
  font-family: Poppins, sans-serif;
  padding: 20px 0px 15px;
}
.card-title span {
  color: #899bbd;
  font-size: 14px;
  font-weight: 400;
}
.card-body {
  padding: 20px;
}
.card-img-overlay {
  background-color: rgba(255, 255, 255, 0.6);
}
// /* Alerts */
// .alert-heading {
//   font-weight: 500;
//   font-family: "Poppins", sans-serif;
//   font-size: 20px;
// }
// /* Close Button */
// .btn-close {
//   background-size: 25%;
// }
// .btn-close:focus {
//   outline: 0;
//   box-shadow: none;
// }
// /* Accordion */
// .accordion-item {
//   border: 1px solid #ebeef4;
// }
// .accordion-button:focus {
//   outline: 0;
//   box-shadow: none;
// }
// .accordion-button:not(.collapsed) {
//   color: #012970;
//   background-color: #f6f9ff;
// }
// .accordion-flush .accordion-button {
//   padding: 15px 0;
//   background: none;
//   border: 0;
// }
// .accordion-flush .accordion-button:not(.collapsed) {
//   box-shadow: none;
//   color: #4154f1;
// }
// .accordion-flush .accordion-body {
//   padding: 0 0 15px 0;
//   color: #3e4f6f;
//   font-size: 15px;
// }
// /* Breadcrumbs */
// .breadcrumb {
//   font-size: 14px;
//   font-family: "Nunito", sans-serif;
//   color: #899bbd;
//   font-weight: 600;
// }
// .breadcrumb a {
//   color: rgb(137, 155, 189);
//   transition: all 0.3s ease 0s;
// }
// .breadcrumb a:hover {
//   color: #51678f;
// }
// .breadcrumb .breadcrumb-item::before {
//   color: #899bbd;
// }
// .breadcrumb .active {
//   color: rgb(81, 103, 143);
//   font-weight: 600;
// }
/* Bordered Tabs */
.nav-tabs-bordered {
  border-bottom: 2px solid #ebeef4;
}
.nav-tabs-bordered .nav-link {
  margin-bottom: -2px;
  border: none;
  color: #2c384e;
}
.nav-tabs-bordered .nav-link:hover, .nav-tabs-bordered .nav-link:focus {
  color: #E8501D;
}
.nav-tabs-bordered .nav-link.active {
  background-color: #fff;
  color: #E8501D;
  border-bottom: 2px solid #E8501D;
}
// /*--------------------------------------------------------------
// # Header
// --------------------------------------------------------------*/
// .logo {
//   line-height: 1;
// }
// @media (min-width: 1200px) {
//   .logo {
//     width: 280px;
//   }
// }
// .logo img {
//   margin-right: 6px;
// }
// .logo span {
//   font-size: 26px;
//   font-weight: 700;
//   color: #012970;
//   font-family: "Nunito", sans-serif;
// }
// .header {
//   z-index: 997;
//   height: 70px;
//   box-shadow: rgba(1, 41, 112, 0.1) 0px 2px 20px;
//   background-color: rgb(255, 255, 255);
//   padding-left: 20px;
//   transition: all 0.5s ease 0s;
// }
// .header .toggle-sidebar-btn {
//   font-size: 24px;
//   padding-left: 10px;
//   cursor: pointer;
//   color: rgb(49, 49, 49);
// }
// .header .search-bar {
//   min-width: 360px;
//   padding: 0px 20px;
// }
// @media (max-width: 1199px) {
//   .header .search-bar {
//     position: fixed;
//     top: 50px;
//     left: 0;
//     right: 0;
//     padding: 20px;
//     box-shadow: 0px 0px 15px 0px rgba(1, 41, 112, 0.1);
//     background: white;
//     z-index: 9999;
//     transition: 0.3s;
//     visibility: hidden;
//     opacity: 0;
//   }
//   .header .search-bar-show {
//     top: 60px;
//     visibility: visible;
//     opacity: 1;
//   }
// }
// .header .search-form {
//   width: 100%;
//   height: 60px;
// }
// .header .search-form input {
//   font-size: 14px;
//   color: rgb(1, 41, 112);
//   width: 100%;
//   background-color: rgb(249, 250, 251);
//   border-width: 0px;
//   border-style: initial;
//   border-color: initial;
//   border-image: initial;
//   padding: 10px 38px 10px 8px;
//   border-radius: 8px;
//   transition: all 0.3s ease 0s;
// }
// .header .search-form input:focus, .header .search-form input:hover {
//   outline: none;
//   border-width: 2px;
//   border-style: solid;
//   border-color: rgb(210, 219, 227);
//   border-image: initial;
// }
// .header .search-form input::placeholder {
//   color: #B4BFD8 !important;
// }
// .header .search-form button {
//   margin-left: -30px;
//   border-width: 0px;
//   border-style: initial;
//   border-color: initial;
//   border-image: initial;
//   padding: 0px;
//   background: none;
// }
// .header .search-form button i {
//   color: rgb(187, 195, 212);
//   font-size: 20px;
//   font-weight: bold !important;
// }
// /*--------------------------------------------------------------
// # Header Nav
// --------------------------------------------------------------*/
// .header-nav ul {
//   list-style: none;
// }
// .header-nav > ul {
//   margin: 0;
//   padding: 0;
// }
// .header-nav .nav-icon {
//   font-size: 26px;
//   color: rgb(49, 49, 49);
//   margin-right: 25px;
//   position: relative;
// }
// .header-nav .nav-profile {
//   color: rgb(49, 49, 49);
// }
// .header-nav .nav-profile img {
//   max-height: 36px;
// }
// .header-nav .nav-profile span {
//   font-size: 14px;
//   font-weight: 600;
// }
// .header-nav .badge-number {
//   position: absolute;
//   inset: -2px -5px auto auto;
//   font-weight: normal;
//   font-size: 12px;
//   padding: 3px 6px;
// }
// .header-nav .notifications {
//   inset: 8px -15px auto auto !important;
// }
// .header-nav .notifications .notification-item {
//   display: flex;
//   align-items: center;
//   padding: 15px 10px;
//   transition: 0.3s;
// }
// .header-nav .notifications .notification-item i {
//   margin: 0 20px 0 10px;
//   font-size: 24px;
// }
// .header-nav .notifications .notification-item h4 {
//   font-size: 16px;
//   font-weight: 600;
//   margin-bottom: 5px;
// }
// .header-nav .notifications .notification-item p {
//   font-size: 13px;
//   margin-bottom: 3px;
//   color: #919191;
// }
// .header-nav .notifications .notification-item:hover {
//   background-color: #f6f9ff;
// }
// .header-nav .messages {
//   inset: 8px -15px auto auto !important;
// }
// .header-nav .messages .message-item {
//   padding: 15px 10px;
//   transition: 0.3s;
// }
// .header-nav .messages .message-item a {
//   display: flex;
// }
// .header-nav .messages .message-item img {
//   margin: 0 20px 0 10px;
//   max-height: 40px;
// }
// .header-nav .messages .message-item h4 {
//   font-size: 16px;
//   font-weight: 600;
//   margin-bottom: 5px;
//   color: #444444;
// }
// .header-nav .messages .message-item p {
//   font-size: 13px;
//   margin-bottom: 3px;
//   color: #919191;
// }
// .header-nav .messages .message-item:hover {
//   background-color: #f6f9ff;
// }
// .header-nav .profile {
//   min-width: 240px;
//   padding-bottom: 0;
//   top: 8px !important;
// }
// .header-nav .profile .dropdown-header h6 {
//   font-size: 18px;
//   margin-bottom: 0;
//   font-weight: 600;
//   color: #444444;
// }
// .header-nav .profile .dropdown-header span {
//   font-size: 14px;
// }
// .header-nav .profile .dropdown-item {
//   font-size: 14px;
//   padding: 10px 15px;
//   transition: 0.3s;
// }
// .header-nav .profile .dropdown-item i {
//   margin-right: 10px;
//   font-size: 18px;
//   line-height: 0;
// }
// .header-nav .profile .dropdown-item:hover {
//   background-color: #f6f9ff;
// }
// /*--------------------------------------------------------------
// # Sidebar
// --------------------------------------------------------------*/
.sidebar {
  position: fixed;
  top: 60px;
  left: 0;
  bottom: 0;
  width: 320px;
//   z-index: 996;
  transition: all 0.3s;
  padding: 30px 20px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #aab7cf transparent;
  box-shadow: 0px 0px 20px rgba(1, 41, 112, 0.1);
  background-color: #fff;
}
// @media (max-width: 1199px) {
//   .sidebar {
//     left: -300px;
//   }
// }
// .sidebar::-webkit-scrollbar {
//   width: 5px;
//   height: 8px;
//   background-color: #fff;
// }
// .sidebar::-webkit-scrollbar-thumb {
//   background-color: #aab7cf;
// }
// @media (min-width: 1200px) {
//   #main, #footer {
//     margin-left: 300px;
//   }
// }
// @media (max-width: 1199px) {
//   .toggle-sidebar .sidebar {
//     left: 0;
//   }
// }
// @media (min-width: 1200px) {
//   .toggle-sidebar #main, .toggle-sidebar #footer {
//     margin-left: 0;
//   }
//   .toggle-sidebar .sidebar {
//     left: -300px;
//   }
// }
// .sidebar-nav {
//   padding: 0;
//   margin: 0;
//   list-style: none;
// }
// .sidebar-nav li {
//   padding: 0;
//   margin: 0;
//   list-style: none;
// }
// .sidebar-nav .nav-item {
//   margin-bottom: 5px;
// }
// .sidebar-nav .nav-heading {
//   font-size: 11px;
//   text-transform: uppercase;
//   color: #899bbd;
//   font-weight: 600;
//   margin: 10px 0 5px 15px;
// }
.sidebar-nav .nav-link {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  background-color: transparent;
  color: rgb(0, 0, 0) !important;
  padding: 10px 15px;
  border-radius: 4px;
  margin-bottom: 10px;
}
// .sidebar-nav .nav-link i {
//   font-size: 26px;
//   margin-right: 10px;
//   color: rgb(0, 0, 0);
// }
// .sidebar-nav .nav-link.collapsed {
//   color: rgb(1, 41, 112);
//   background: rgb(255, 255, 255);
// }
// .sidebar-nav .nav-link.collapsed i {
//   color: rgb(0, 0, 0);
// }
// .sidebar-nav .nav-link:hover {
//   color: rgb(0, 0, 0) !important;
//   background: rgb(150, 212, 17);
// }
// .sidebar-nav .nav-link:hover i {
//   color: rgb(0, 0, 0);
// }
// .sidebar-nav .nav-link .bi-chevron-down {
//   margin-right: 0;
//   transition: transform 0.2s ease-in-out;
// }
// .sidebar-nav .nav-link:not(.collapsed) .bi-chevron-down {
//   transform: rotate(180deg);
// }
// .sidebar-nav .nav-content {
//   padding: 5px 0 0 0;
//   margin: 0;
//   list-style: none;
// }
// .sidebar-nav .nav-content a {
//   display: flex;
//   align-items: center;
//   font-size: 14px;
//   font-weight: 600;
//   color: #012970;
//   transition: 0.3;
//   padding: 10px 0 10px 40px;
//   transition: 0.3s;
// }
// .sidebar-nav .nav-content a i {
//   font-size: 6px;
//   margin-right: 8px;
//   line-height: 0;
//   border-radius: 50%;
// }
// .sidebar-nav .nav-content a:hover, .sidebar-nav .nav-content a.active {
//   color: #4154f1;
// }
// .sidebar-nav .nav-content a.active i {
//   background-color: #4154f1;
// }
// /*--------------------------------------------------------------
// # Dashboard
// --------------------------------------------------------------*/
// /* Filter dropdown */
// .dashboard .filter {
//   position: absolute;
//   right: 0px;
//   top: 15px;
// }
// .dashboard .filter .icon {
//   color: #aab7cf;
//   padding-right: 20px;
//   padding-bottom: 5px;
//   transition: 0.3s;
//   font-size: 16px;
// }
// .dashboard .filter .icon:hover, .dashboard .filter .icon:focus {
//   color: #4154f1;
// }
// .dashboard .filter .dropdown-header {
//   padding: 8px 15px;
// }
// .dashboard .filter .dropdown-header h6 {
//   text-transform: uppercase;
//   font-size: 14px;
//   font-weight: 600;
//   letter-spacing: 1px;
//   color: #aab7cf;
//   margin-bottom: 0;
//   padding: 0;
// }
// .dashboard .filter .dropdown-item {
//   padding: 8px 15px;
// }
// /* Info Cards */
// .dashboard .info-card {
//   padding-bottom: 10px;
// }
// .dashboard .info-card h6 {
//   font-size: 28px;
//   color: rgb(49, 49, 49);
//   font-weight: 700 !important;
//   margin: 0px;
//   padding: 0px;
// }
// .dashboard .card-icon {
//   font-size: 48px;
//   line-height: 0;
//   width: 80px;
//   height: 80px;
//   flex-shrink: 0;
//   flex-grow: 0;
// }
// .dashboard .sales-card .card-icon {
//   color: rgb(255, 255, 255);
//   background: rgb(150, 212, 18);
// }
// .dashboard .revenue-card .card-icon {
//   color: rgb(255, 255, 255);
//   background: rgb(232, 80, 29);
// }
// .dashboard .customers-card .card-icon {
//   color: rgb(255, 255, 255);
//   background: rgb(52, 195, 240);
// }
// /* Activity */
// .dashboard .activity {
//   font-size: 14px;
// }
// .dashboard .activity .activity-item .activite-label {
//   color: #888;
//   position: relative;
//   flex-shrink: 0;
//   flex-grow: 0;
//   min-width: 64px;
// }
// .dashboard .activity .activity-item .activite-label::before {
//   content: "";
//   position: absolute;
//   right: -11px;
//   width: 4px;
//   top: 0;
//   bottom: 0;
//   background-color: #eceefe;
// }
// .dashboard .activity .activity-item .activity-badge {
//   margin-top: 3px;
//   z-index: 1;
//   font-size: 11px;
//   line-height: 0;
//   border-radius: 50%;
//   flex-shrink: 0;
//   border: 3px solid #fff;
//   flex-grow: 0;
// }
// .dashboard .activity .activity-item .activity-content {
//   padding-left: 10px;
//   padding-bottom: 20px;
// }
// .dashboard .activity .activity-item:first-child .activite-label::before {
//   top: 5px;
// }
// .dashboard .activity .activity-item:last-child .activity-content {
//   padding-bottom: 0;
// }
// /* News & Updates */
// .dashboard .news .post-item + .post-item {
//   margin-top: 15px;
// }
// .dashboard .news img {
//   width: 80px;
//   float: left;
//   border-radius: 5px;
// }
// .dashboard .news h4 {
//   font-size: 15px;
//   margin-left: 95px;
//   font-weight: bold;
//   margin-bottom: 5px;
// }
// .dashboard .news h4 a {
//   color: #012970;
//   transition: 0.3s;
// }
// .dashboard .news h4 a:hover {
//   color: #4154f1;
// }
// .dashboard .news p {
//   font-size: 14px;
//   color: #777777;
//   margin-left: 95px;
// }
// /* Recent Sales */
// .dashboard .recent-sales {
//   font-size: 14px;
// }
// .dashboard .recent-sales .table thead {
//   background: #f6f6fe;
// }
// .dashboard .recent-sales .table thead th {
//   border: 0;
// }
// .dashboard .recent-sales .dataTable-top {
//   padding: 0 0 10px 0;
// }
// .dashboard .recent-sales .dataTable-bottom {
//   padding: 10px 0 0 0;
// }
// /* Top Selling */
// .dashboard .top-selling {
//   font-size: 14px;
// }
// .dashboard .top-selling .table thead {
//   background: #f6f6fe;
// }
// .dashboard .top-selling .table thead th {
//   border: 0;
// }
// .dashboard .top-selling .table tbody td {
//   vertical-align: middle;
// }
// .dashboard .top-selling img {
//   border-radius: 5px;
//   max-width: 60px;
// }
// /*--------------------------------------------------------------
// # Icons list page
// --------------------------------------------------------------*/
// .iconslist {
//   display: grid;
//   max-width: 100%;
//   grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
//   gap: 1.25rem;
//   padding-top: 15px;
// }
// .iconslist .icon {
//   background-color: #fff;
//   border-radius: 0.25rem;
//   text-align: center;
//   color: #000000;
//   padding: 15px 0;
// }
// .iconslist i {
//   margin: 0.25rem;
//   font-size: 2.5rem;
// }
// .iconslist .label {
//   font-family: var(--bs-font-monospace);
//   display: inline-block;
//   width: 100%;
//   overflow: hidden;
//   padding: 0.25rem;
//   font-size: 12px;
//   text-overflow: ellipsis;
//   white-space: nowrap;
//   color: #666;
// }
// /*--------------------------------------------------------------
// # Profie Page
// --------------------------------------------------------------*/
// .profile .profile-card img {
//   max-width: 120px;
// }
// .profile .profile-card h2 {
//   font-size: 24px;
//   font-weight: 700;
//   color: #2c384e;
//   margin: 10px 0 0 0;
// }
// .profile .profile-card h3 {
//   font-size: 18px;
// }
// .profile .profile-card .social-links a {
//   font-size: 20px;
//   display: inline-block;
//   color: rgba(1, 41, 112, 0.5);
//   line-height: 0;
//   margin-right: 10px;
//   transition: 0.3s;
// }
// .profile .profile-card .social-links a:hover {
//   color: #012970;
// }
// .profile .profile-overview .row {
//   margin-bottom: 20px;
//   font-size: 15px;
// }
// .profile .profile-overview .card-title {
//   color: #012970;
// }
// .profile .profile-overview .label {
//   font-weight: 600;
//   color: rgba(1, 41, 112, 0.6);
// }
// .profile .profile-edit label {
//   font-weight: 600;
//   color: rgba(1, 41, 112, 0.6);
// }
// .profile .profile-edit img {
//   max-width: 120px;
// }
// /*--------------------------------------------------------------
// # F.A.Q Page
// --------------------------------------------------------------*/
// .faq .basic h6 {
//   font-size: 18px;
//   font-weight: 600;
//   color: #4154f1;
// }
// .faq .basic p {
//   color: #6980aa;
// }
// /*--------------------------------------------------------------
// # Contact
// --------------------------------------------------------------*/
// .contact .info-box {
//   padding: 28px 30px;
// }
// .contact .info-box i {
//   font-size: 38px;
//   line-height: 0;
//   color: #4154f1;
// }
// .contact .info-box h3 {
//   font-size: 20px;
//   color: #012970;
//   font-weight: 700;
//   margin: 20px 0 10px 0;
// }
// .contact .info-box p {
//   padding: 0;
//   line-height: 24px;
//   font-size: 14px;
//   margin-bottom: 0;
// }
// .contact .php-email-form .error-message {
//   display: none;
//   color: #fff;
//   background: #ed3c0d;
//   text-align: left;
//   padding: 15px;
//   margin-bottom: 24px;
//   font-weight: 600;
// }
// .contact .php-email-form .sent-message {
//   display: none;
//   color: #fff;
//   background: #18d26e;
//   text-align: center;
//   padding: 15px;
//   margin-bottom: 24px;
//   font-weight: 600;
// }
// .contact .php-email-form .loading {
//   display: none;
//   background: #fff;
//   text-align: center;
//   padding: 15px;
//   margin-bottom: 24px;
// }
// .contact .php-email-form .loading:before {
//   content: "";
//   display: inline-block;
//   border-radius: 50%;
//   width: 24px;
//   height: 24px;
//   margin: 0 10px -6px 0;
//   border: 3px solid #18d26e;
//   border-top-color: #eee;
//   animation: animate-loading 1s linear infinite;
// }
// .contact .php-email-form input, .contact .php-email-form textarea {
//   border-radius: 0;
//   box-shadow: none;
//   font-size: 14px;
//   border-radius: 0;
// }
// .contact .php-email-form input:focus, .contact .php-email-form textarea:focus {
//   border-color: #4154f1;
// }
// .contact .php-email-form input {
//   padding: 10px 15px;
// }
// .contact .php-email-form textarea {
//   padding: 12px 15px;
// }
// .contact .php-email-form button[type=submit] {
//   background: #4154f1;
//   border: 0;
//   padding: 10px 30px;
//   color: #fff;
//   transition: 0.4s;
//   border-radius: 4px;
// }
// .contact .php-email-form button[type=submit]:hover {
//   background: #5969f3;
// }
// @keyframes animate-loading {
//   0% {
//     transform: rotate(0deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }
// /*--------------------------------------------------------------
// # Error 404
// --------------------------------------------------------------*/
.error-404 {
  padding: 30px;
}
.error-404 h1 {
  font-size: 180px;
  font-weight: 700;
  color: #4154f1;
  margin-bottom: 0;
  line-height: 150px;
}
.error-404 h2 {
  font-size: 24px;
  font-weight: 700;
  color: #fd5238;
  margin-bottom: 30px;
}
.error-404 .btn {
  color: #fff;
  padding: 8px 30px;
}
.error-404 .btn:hover {
  background: #3e4f6f;
}
@media (min-width: 992px) {
  .error-404 img {
    max-width: 50%;
  }
}
// /*--------------------------------------------------------------
// # Footer
// --------------------------------------------------------------*/
// .footer {
//   padding: 20px 0;
//   font-size: 14px;
//   transition: all 0.3s;
//   border-top: 1px solid #cddfff;
// }
// .footer .copyright {
//   text-align: center;
//   color: #012970;
// }
// .footer .credits {
//   padding-top: 5px;
//   text-align: center;
//   font-size: 13px;
//   color: #012970;
// }

.clickable-cell {
  cursor: pointer;
  /* Add more styling as needed */
}

.table-responsive {
  overflow-x: auto;
}

// table {
//   width: 100%;
//   border-collapse: collapse;
// }

// th, td {
//   padding: 8px;
//   text-align: left;
//   border-bottom: 1px solid #ddd;
// }

// /* Zebra striping */
// tr:nth-child(odd) {
//   background-color: #f9f9f9; /* Light color for odd rows */
// }

// tr:nth-child(even) {
//   background-color: #e9e9e9; /* Slightly different color for even rows */
// }

